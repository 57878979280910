import request from "./request";

// 查询企业信息
export function associationManuInfo(params) {
  return request({
    url: "/client/user/associationManuInfo",
    method: "get",
    params
  });
}
// 邀请的企业
export function inviteManu(params) {
  return request({
    url: "/client/user/inviteManu",
    method: "get",
    params
  });
}
// 关联
export function associationManu(params) {
  return request({
    url: "/client/user/associationManu",
    method: "get",
    params
  });
}
// 拒绝邀请
export function rejectInvitation(params) {
  return request({
    url: "/client/user/rejectInvitation",
    method: "get",
    params
  });
}
// 创建企业
export function systemManu(data) {
  return request({
    url: "/system/manu/",
    method: "post",
    data
  });
}
// 推出企业
export function exitManu(params) {
  return request({
    url: "/client/user/exitManu",
    method: "get",
    params
  });
}
// 查询成员
export function queryUserByPhone(params) {
  return request({
    url: "/client/user/queryUserByPhone",
    method: "get",
    params
  });
}
// 获取企业下成员
export function queryManuStaff(params) {
  return request({
    url: "/system/manu/queryManuStaff",
    method: "get",
    params
  });
}
// 邀请成员
export function invitePersonnel(params) {
  return request({
    url: "/system/manu/invitePersonnel",
    method: "get",
    params
  });
}
// 删除成员
export function deleteMember(params) {
  return request({
    url: "/client/user/deleteMember",
    method: "get",
    params
  });
}
// 设为管理员
export function editRole(params) {
  return request({
    url: "/client/user/editRole",
    method: "get",
    params
  });
}

// 查询所属企业信息
export function queryManuFactoryInfo() {
  return request({
    url: "/system/manu/queryManuFactoryInfo",
    method: "get",
  });
}
// 修改
export function upDateManu(data) {
  return request({
    url: "/system/manu",
    method: "put",
    data
  });
}

// 查询企业信息
export function getManu(params) {
  return request({
    url: "/system/manu",
    method: "get",
    params
  });
}