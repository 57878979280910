<template>
  <div class="Information">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="企业全称" prop="name">
        <span v-if="userInfo.signatureStatus == 0">{{ ruleForm.name }}</span>
        <el-input v-else v-model="ruleForm.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="manufactoryCode">
        <span v-if="userInfo.signatureStatus == 0">{{
          ruleForm.manufactoryCode
        }}</span>
        <el-input
          v-else
          v-model="ruleForm.manufactoryCode"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="企业类型" prop="factoryType">
        <el-select v-model="ruleForm.factoryType" placeholder="请选企业类型">
          <el-option
            v-for="item in dict.sys_manu_type"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="ruleForm.contactPhone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="地区" prop="address">
        <el-cascader
        style="width:100%;"
          v-model="ruleForm.address"
          :options="$store.state.area.areaList"
        />
      </el-form-item>
      <el-form-item style="display: flex;justify-content:end;margin-top:40px">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { useDict } from "@/utils/dict";
import { mapState } from "vuex";
import { queryManuFactoryInfo, upDateManu } from "@/api/personnelManagement";
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    let checkManufactoryCode = (rule, value, callback) => {
      // 信用代码
      if (!value) {
        return callback(new Error("社会信用代码不能为空"));
      } else {
        const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的社会信用代码"));
        }
      }
    };
    return {
      ruleForm: {
        name: "",
      },
      dict: {},
      rules: {
        contactPhone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        factoryType: [
          { required: true, message: "请输入企业类型", trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        address: [{ required: true, message: "请选择地区", trigger: "blur" }],
        manufactoryCode: [
          { required: true, validator: checkManufactoryCode, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.dict = useDict("sys_manu_type");
    this.getData();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.address) {
            let address = JSON.parse(JSON.stringify(this.ruleForm.address));
            address = address.join("-");
            let { id, factoryType, contactPhone, name, manufactoryCode } =
              this.ruleForm;
            let param = {
              id,
              factoryType,
              contactPhone,
              address,
              name,
              manufactoryCode,
            };
            upDateManu(param).then(({ code, msg }) => {
              if (code == 200) {
                this.$message.success(msg);
                this.getData();
              } else {
                this.$message.error(msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getData() {
      queryManuFactoryInfo().then(({ code, data }) => {
        if (code == 200) {
          let arr = data.address.split("-");
          this.ruleForm = data;
          this.ruleForm.address = arr;
        }
      });
    },
    resetForm() {
      this.ruleForm.factoryType = "";
      this.ruleForm.contactPhone = "";
      this.ruleForm.address = "";
    },
    //  this.areaList = this.$store.state.area.areaList;
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.Information {
  margin-top: 20px;
  margin-left: 30px;
  width: 600px;
}
</style>
